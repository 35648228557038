import { Box } from "@mui/material";
import { ConfirmCESummary } from "containers/Projects/components/CompEvents/CompEventActionModal/components/ConfirmCEAction/ConfirmCESummary";
import { ReasonSummary } from "containers/Projects/components/ActionModal/ReasonSummary";
import { CompEventHistoryItem, Contract, ProductType } from "generated/graphql";
import { memo, useContext } from "react";
import { HistoryItemMeta } from "../../HistoryModal/HistoryItemMeta";
import { CompEventWidgetContext } from "containers/Projects/components/CompEvents/CompEventWidget/CompEventWidget.context";
import { QuotationsTable } from "containers/Projects/components/CompEvents/CompEventActionModal/components/SubmitQuotationCEAction/QuotationsTable/QuotationsTable";
import { useTranslation } from "react-i18next";
import { NotifyCERequestQuotationSummary } from "containers/Projects/components/CompEvents/CompEventActionModal/components/NotifyAndRequestQuotationCEAction/NotifyCERequestQuotationSummary";
import { AssessmentsTable } from "containers/Projects/components/CompEvents/CompEventActionModal/components/SubmitOwnAssessmentCEAction/AssessmentsTable/AssessmentsTable";
import { RequestGrantMoreTimeSummary } from "containers/Projects/components/CompEvents/CompEventActionModal/components/RequestForMoreTimeCEAction/RequestGrantMoreTimeSummary";
import { NotifyOfSilenceCENoticeHistorySummary } from "containers/Projects/components/CompEvents/CompEventActionModal/components/NotifyOfSilenceCEAction/NotifyOfSilenceCENoticeHistorySummary";
import { NotifyOfSilenceCEQuotationHistorySummary } from "containers/Projects/components/CompEvents/CompEventActionModal/components/NotifyOfSilenceCEAction/NotifyOfSilenceCEQuotationHistorySummary";
import { useCEQuotationAssessmentModal } from "../CEQuotationAssessmentModal/useCEQuotationAssessmentModal";
import { CEQuotationAssessmentReadOnlyModal } from "../CEQuotationAssessmentReadOnlyModal/CEQuotationAssessmentReadOnlyModal";
import { NotifyCEClaimSummary } from "containers/Projects/components/ActionModal/NotifyCEClaimSummary";

export type CEHistoryItemDetailsSummaryProps = {
  historyItem: CompEventHistoryItem;
  contract: Contract;
};

export const CEHistoryItemDetailsSummary: React.FC<
  CEHistoryItemDetailsSummaryProps
> = ({ historyItem, contract }) => {
  const { t } = useTranslation();

  const {
    modalVisibility: quotationModalVisibility,
    toggleModalVisibility: toggleQuotationModalVisibility,
  } = useCEQuotationAssessmentModal();

  if (historyItem.noticeAnswers) {
    return (
      <NotifyCEClaimSummary
        claimType={`${historyItem.noticeAnswers.claimType.clause} ${historyItem.noticeAnswers.claimType.description}`}
        ewTitle={historyItem.noticeAnswers.earlyWarning?.title}
        contextType="compEvent"
      />
    );
  } else if (historyItem.noticeRequestQuotationAnswers) {
    return (
      <NotifyCERequestQuotationSummary
        claimType={`${historyItem.noticeRequestQuotationAnswers.claimType.clause} ${historyItem.noticeRequestQuotationAnswers.claimType.description}`}
        assumptions={
          historyItem.noticeRequestQuotationAnswers.assumptions ?? ""
        }
      />
    );
  } else if (historyItem.noticeProposedInstructionAnswers) {
    return (
      <NotifyCERequestQuotationSummary
        claimType={`${historyItem.noticeProposedInstructionAnswers.claimType.clause} ${historyItem.noticeProposedInstructionAnswers.claimType.description}`}
        assumptions={
          historyItem.noticeProposedInstructionAnswers.assumptions ?? ""
        }
      />
    );
  } else if (historyItem.confirmationAnswers) {
    return (
      <ConfirmCESummary
        assumptions={historyItem.confirmationAnswers.assumptions}
        ewCouldveBeenGiven={historyItem.confirmationAnswers.ewCouldveBeenGiven}
      />
    );
  } else if (historyItem.rejectionAnswers) {
    return <ReasonSummary reason={historyItem.rejectionAnswers.reason} />;
  } else if (historyItem.abandonInstructionAnswers) {
    return (
      <ReasonSummary
        reason={historyItem.abandonInstructionAnswers.remarks}
        label={t("common.labels.remarks")}
      />
    );
  } else if (historyItem.ownAssessmentNoticeAnswers) {
    return (
      <ReasonSummary reason={historyItem.ownAssessmentNoticeAnswers.reason} />
    );
  } else if (historyItem.ownAssessmentAnswers) {
    const currency =
      contract.valueCurrency ??
      historyItem.ownAssessmentAnswers.assessment?.currency ??
      historyItem.authorizationWorkflowAudit?.action.productInstance.contract
        .valueCurrency;

    return (
      <>
        <CEQuotationAssessmentReadOnlyModal
          open={quotationModalVisibility}
          onClose={toggleQuotationModalVisibility}
          quotationAssessment={
            historyItem.isIncomplete
              ? historyItem.ownAssessmentAnswers.draftAssessment!
              : historyItem.ownAssessmentAnswers.assessment!
          }
          contract={contract}
        />
        <AssessmentsTable
          assessments={
            historyItem.isIncomplete /* at the time of the authorization, the historyItem is not complete and the assessment is stil in draft mode */
              ? [historyItem.ownAssessmentAnswers.draftAssessment!]
              : [historyItem.ownAssessmentAnswers.assessment!]
          }
          contractCurrency={currency ?? ""}
          onRowClick={toggleQuotationModalVisibility}
        />
      </>
    );
  } else if (historyItem.requestForMoreTimeAnswers) {
    return (
      <RequestGrantMoreTimeSummary
        remarks={historyItem.requestForMoreTimeAnswers.remarks ?? undefined}
        days={historyItem.requestForMoreTimeAnswers.requestedDays}
        isRequest
      />
    );
  } else if (historyItem.denyingOfMoreTimeAnswers) {
    return (
      <ReasonSummary
        reason={historyItem.denyingOfMoreTimeAnswers.remarks ?? undefined}
        label={t("common.labels.remarks")}
      />
    );
  } else if (historyItem.grantingOfMoreTimeAnswers) {
    return (
      <RequestGrantMoreTimeSummary
        remarks={historyItem.grantingOfMoreTimeAnswers.remarks ?? undefined}
        days={historyItem.grantingOfMoreTimeAnswers.grantedDays}
        isRequest={false}
      />
    );
  } else if (historyItem.quotationAnswers) {
    const currency =
      contract.valueCurrency ??
      (historyItem.quotationAnswers.preferredQuotation?.currency as any); // TODO will be fixed in AD ticket #9709

    return (
      <>
        <CEQuotationAssessmentReadOnlyModal
          open={quotationModalVisibility}
          onClose={toggleQuotationModalVisibility}
          quotationAssessment={
            historyItem.quotationAnswers
              .preferredQuotation as any /* TODO will be fixed in AD ticket #9709 */
          }
          contract={contract}
        />
        <QuotationsTable
          quotations={[
            historyItem.quotationAnswers
              .preferredQuotation as any /* TODO will be fixed in AD ticket #9709 */,
          ]}
          contractCurrency={currency}
          onRowClick={toggleQuotationModalVisibility}
        />
      </>
    );
  } else if (historyItem.quotationRevisionRequestAnswers) {
    return (
      <ReasonSummary
        reason={historyItem.quotationRevisionRequestAnswers.reason ?? undefined}
      />
    );
  } else if (historyItem.quotationAcceptanceAnswers) {
    const currency =
      contract.valueCurrency ??
      historyItem.quotationAcceptanceAnswers.acceptedQuotation.currency;

    return (
      <>
        <CEQuotationAssessmentReadOnlyModal
          open={quotationModalVisibility}
          onClose={toggleQuotationModalVisibility}
          quotationAssessment={
            historyItem.quotationAcceptanceAnswers.acceptedQuotation
          }
          contract={contract}
        />
        <QuotationsTable
          quotations={[
            historyItem.quotationAcceptanceAnswers.acceptedQuotation,
          ]}
          contractCurrency={currency}
          onRowClick={toggleQuotationModalVisibility}
        />
      </>
    );
  } else if (historyItem.noticeOfSilenceCompEventNoticeAnswers) {
    return (
      <NotifyOfSilenceCENoticeHistorySummary
        remarks={historyItem.noticeOfSilenceCompEventNoticeAnswers.remarks}
        deemedAcceptedDate={
          historyItem.noticeOfSilenceCompEventNoticeAnswers.deemedAcceptedDate
        }
      />
    );
  } else if (historyItem.noticeOfSilenceQuotationAnswers) {
    return (
      <NotifyOfSilenceCEQuotationHistorySummary
        remarks={historyItem.noticeOfSilenceQuotationAnswers.remarks}
        deemedAcceptedDate={
          historyItem.noticeOfSilenceQuotationAnswers.deemedAcceptedDate
        }
        acceptedQuotation={
          historyItem.noticeOfSilenceQuotationAnswers.acceptedQuotation
        }
      />
    );
  } else if (historyItem.noticeOfSilenceOwnAssessmentAnswers) {
    return (
      <NotifyOfSilenceCEQuotationHistorySummary
        remarks={historyItem.noticeOfSilenceOwnAssessmentAnswers.remarks}
        deemedAcceptedDate={
          historyItem.noticeOfSilenceOwnAssessmentAnswers.deemedAcceptedDate
        }
        acceptedQuotation={
          historyItem.noticeOfSilenceOwnAssessmentAnswers.acceptedQuotation
        }
      />
    );
  }

  return null;
};

type CEHistoryItemDataViewProps = {
  historyItem: CompEventHistoryItem;
};

export const CEHistoryItemDataView: React.FC<CEHistoryItemDataViewProps> = memo(
  ({ historyItem }) => {
    const { contract } = useContext(CompEventWidgetContext);

    return (
      <Box display="flex" flexDirection="column">
        <CEHistoryItemDetailsSummary
          historyItem={historyItem}
          contract={contract}
        />
        <Box mt={3}>
          <HistoryItemMeta
            contractTimezone={contract.timeZone}
            historyItem={historyItem}
            productType={ProductType.CompEvents}
          />
        </Box>
      </Box>
    );
  }
);
